import React from "react"
import { compose } from "redux"
import includes from "lodash/includes"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import "moment-timezone"
import moment from "moment"

import { ButtonSet } from "components/buttons/button-set"
import DesktopViewOnly from "components/desktop-view"
import SignatureModal from "components/envelope/modal/signature-modal"
import TermsModalBody from "components/templates/terms-modal"
import ConfirmationModal from "components/templates/confirmation-modal"
import HandleBeforeUnload from "components/handle-before-unload"
import {
  Container,
  Wrapper,
  Header,
  IconBeforeText,
  FooterContainer,
  DocumentList,
} from "components/envelope"
import { APPLICATION_TYPE } from "containers/rental-submission/constants"
import EDIT_ICON from "images/icon/e-sign/edit-icon-black.svg"
import {
  findSignerByRecipient,
  filterFieldsBySigner,
  filterPages,
} from "helpers/signing"
import {
  LRA_REVIEW_AND_SIGN,
  SIGNATURE_TYPE_PROPERTIES,
} from "tracker/tracker-const"
import useTracker from "tracker/use-tracker"
import { tracker } from "tracker"

import {
  TYPES_COMPONENT,
  SIGNABLE_COMPONENT,
  CONFIRM_MODAL_TEXT,
  HEADER_TITLE,
  TERMS_AND_CONDITIONS,
} from "./constants"
import DropBoxWrapper from "../pdf-pages/drop-box-wrapper"
import SignFlag from "../sign-flag"
import PopperDropdown from "./popper-drop-down"
import { DOCUMENT_LIST_ID, RECIPIENT_ROLES } from "../constants"
import { withReducer, withSaga } from "../connect"
import { withConnect } from "./connect"

export const TopbarAndHeader = () => (
  <Header>
    <div>
      <IconBeforeText src={EDIT_ICON} alt="IconSign" />
      <span className="text-title">{HEADER_TITLE}</span>
    </div>
  </Header>
)

export const EnvelopeSign = ({
  envelope,
  haveEnvelope,
  activeId,
  actions,
  recipientsList,
  fieldValidation,
  signatureData,
  isLoadingUpdateEnvelope,
  isSubmittingRental,
  match,
  // isOwner,
  shouldHandleUnload,
  isUploadingSignature,
  isLoadingSavingFile,
  // history,
  isSigned,
  isAcceptedConsent,
  updateConsentSuccess,
  isLoadingUpdateConsent,
  error,
  isLoadingGenerateEnvelopePDF,
  uploadSignatureSuccess,
  isSignatureEdited,
  isLoadingGeneratePdfFileURL,
  rentalSubmission,
}) => {
  const [showSignError, setShowSignError] = React.useState(false)
  const [showSignModal, setShowSignModal] = React.useState({
    show: false,
    mode: "newSign",
  })
  const [showModal, setShowModal] = React.useState(false)
  const [showSignConfirmModal, setShowSignConfirmModal] = React.useState(false)
  const [showConsentModal, setShowConsentModal] = React.useState(
    !isAcceptedConsent,
  )
  const [selectedCheckBox, setSelectedCheckBox] = React.useState(false)
  const signFlagRef = React.useRef(null)

  const envelopeId = get(match, "params.envelopeId")
  const { recipients } = envelope
  const { token: queryToken } = findSignerByRecipient(recipients)

  const validationErrors = get(fieldValidation, "validationErrors", {})
  const signErrors = get(fieldValidation, "signErrors", {})
  const isAllFieldValidationValid = fieldValidation.isValid

  const shouldDisableButton =
    isLoadingGenerateEnvelopePDF ||
    isLoadingSavingFile ||
    isLoadingGeneratePdfFileURL ||
    !isAllFieldValidationValid

  const dropdownItems = [
    {
      label: "Edit",
      onClick: () => {
        setShowSignModal({ show: !showModal })
      },
    },
  ]

  const simulateClickSignFlag = () => signFlagRef.current?.click()

  const onClickFinish = () => {
    if (!isAllFieldValidationValid) {
      setShowSignError(true)
      simulateClickSignFlag()
    } else {
      setShowSignError(false)
      setShowSignConfirmModal(true)
    }
  }

  const signApplication = () => {
    // track event
    tracker.trackEvent(LRA_REVIEW_AND_SIGN.CLICK_CONFIRM_FINISH_SIGN)

    const { files } = envelope
    const signer = findSignerByRecipient(recipients)
    const newFiles = filterPages(
      filterFieldsBySigner(files, fields =>
        fields.filter(field =>
          // Check the email because we need to support the old signer that only has the email
          [
            get(signer, "email"),
            get(signer, "_id"),
            get(signer, "roleId"),
          ].includes(field.assignee),
        ),
      ),
      pages => pages.filter(page => !isEmpty(page.fields)),
    )
    const filesData = { files: newFiles }
    const isNextToDocumentStep = !!rentalSubmission?.screeningOption?.income

    actions.updateEnvelopeSign({
      ...filesData,
      isNextToDocumentStep,
    })
  }

  React.useEffect(
    () => {
      if (isSigned) {
        actions.redirect(null, true)
      }
      setShowSignModal({
        show:
          (includes(SIGNABLE_COMPONENT, activeId.type, false) &&
            activeId.canEdit &&
            isEmpty(activeId.value) &&
            isEmpty(signatureData)) ||
          showModal,
        mode: isEmpty(activeId.value) ? "newSign" : "editSign",
      })

      if (
        activeId.type === TYPES_COMPONENT.DATE_SIGNS &&
        isEmpty(activeId.value)
      ) {
        actions.dateSignedSave({
          dateSigned: moment()
            .toDate()
            .valueOf(),
          timeZone: moment.tz.guess(true),
        })
        simulateClickSignFlag()
      }
      if (updateConsentSuccess) {
        setShowConsentModal(false)
      }
    },
    [
      activeId.type,
      activeId.value,
      activeId.canEdit,
      signatureData,
      showModal,
      updateConsentSuccess,
    ],
  )

  React.useEffect(
    () => {
      if (!showSignModal.show && uploadSignatureSuccess && !isSignatureEdited)
        simulateClickSignFlag()
    },
    [showSignModal.show, uploadSignatureSuccess],
  )

  useTracker(LRA_REVIEW_AND_SIGN.ENTER_APPLICATION_SIGN, {
    template: APPLICATION_TYPE.CAR_LRA,
    recipient_role: RECIPIENT_ROLES.APPLICANT,
  })

  let activeBox = ""
  if (
    !isEmpty(activeId) &&
    activeId.canEdit &&
    activeId.value &&
    includes(SIGNABLE_COMPONENT, activeId.type, false)
  ) {
    activeBox = showModal || showSignModal.show ? "" : activeId.fieldId
  }

  const handleClickConsent = () => {
    actions.updateEnvelopeConsent({ isAcceptedConsent: true }, envelopeId, {
      token: queryToken,
    })
  }

  const onCheckBoxClick = () => {
    setSelectedCheckBox(!selectedCheckBox)
  }

  const handleCloseModal = () => {
    actions.resetActive()
    setShowModal(false)
  }

  const handleEditSignature = value => {
    actions.editSignSignature({ signatureData: value, activeId })
    setShowModal(false)
    tracker.trackEvent(
      LRA_REVIEW_AND_SIGN.REPLACE_SIGNATURE,
      SIGNATURE_TYPE_PROPERTIES.TYPE,
    )
  }

  const handleSaveSignature = value => {
    actions.saveSignSignature({ signatureData: value, activeId })
    tracker.trackEvent(
      LRA_REVIEW_AND_SIGN.ADOPT_SIGNATURE,
      SIGNATURE_TYPE_PROPERTIES.TYPE,
    )
    simulateClickSignFlag()
  }

  const handleUploadEditSignature = value => {
    actions.uploadEditSignSignature(value)
  }

  const handleUploadSaveSignature = value => {
    actions.uploadSaveSignSignature(value)
  }

  const onClickBackToGuidePage = () => actions.redirect(null, true)

  const handleClickCloseOnConfirmationModal = () => {
    tracker.trackEvent(LRA_REVIEW_AND_SIGN.CLICK_KEEP_EDITING)
    setShowSignConfirmModal(false)
  }

  if (
    includes(
      [TYPES_COMPONENT.INITIAL_SIGNS, TYPES_COMPONENT.SIGNATURE_SIGNS],
      activeId.type,
      false,
    ) &&
    isEmpty(activeId.value) &&
    !isEmpty(signatureData)
  ) {
    actions.saveSignSignature({ signatureData, activeId })
    simulateClickSignFlag()
  }
  return (
    <>
      <PopperDropdown popper={activeBox} dropdownItems={dropdownItems} />
      {shouldHandleUnload && <HandleBeforeUnload />}
      <Container id="envelopePage" data-testid="envelopePage">
        {haveEnvelope && <DesktopViewOnly />}
        <TopbarAndHeader />
        <SignFlag
          ref={signFlagRef}
          template={APPLICATION_TYPE.CAR_LRA}
          showSignError={showSignError}
          setShowSignError={setShowSignError}
        />
        <Wrapper>
          <DocumentList center id={DOCUMENT_LIST_ID}>
            <DropBoxWrapper
              viewMode
              recipientsList={recipientsList}
              actions={actions}
              validationErrors={validationErrors}
              showSignError={showSignError}
              signErrors={signErrors}
            />
          </DocumentList>
        </Wrapper>
      </Container>
      <FooterContainer>
        <ButtonSet
          id="eSignPageButtonSet"
          onSaveAndNext={onClickFinish}
          continueText="Finish signing"
          onClickBackToDashboardBtn={onClickBackToGuidePage}
          margin="0 0"
          hidePrevButton
          hideIconNextButton
          text="Back to guide"
          disabledBackButton={shouldDisableButton}
          disabledNextButton={shouldDisableButton}
        />
      </FooterContainer>
      <SignatureModal
        envelopeId={envelopeId}
        userToken={queryToken}
        showSignModal={showSignModal}
        signatureData={signatureData}
        closeModal={handleCloseModal}
        saveSignSignature={handleSaveSignature}
        editSignSignature={handleEditSignature}
        uploadSaveSignSignature={handleUploadSaveSignature}
        uploadEditSignSignature={handleUploadEditSignature}
        isUploadingSignature={isUploadingSignature}
      />
      <TermsModalBody
        isOpen={showConsentModal}
        closeButton={false}
        title={TERMS_AND_CONDITIONS.TITLE}
        terms={TERMS_AND_CONDITIONS.TERMS}
        error={error}
        selectedCheckBox={selectedCheckBox}
        onCheckBoxClick={onCheckBoxClick}
        handleClickBtn={handleClickConsent}
        isLoading={
          isLoadingUpdateConsent ||
          isLoadingGenerateEnvelopePDF ||
          isLoadingSavingFile
        }
        onClearError={actions.clearError}
      />
      <ConfirmationModal
        id="signConfirmModal"
        title={CONFIRM_MODAL_TEXT.TITLE}
        content={CONFIRM_MODAL_TEXT.INFO}
        confirmBtnTxt={CONFIRM_MODAL_TEXT.SUBMIT}
        cancelBtnTxt={CONFIRM_MODAL_TEXT.CANCEL}
        btnNextMinWidth="134"
        canBeClosedByClickOverlay={false}
        isOpen={showSignConfirmModal}
        onClose={handleClickCloseOnConfirmationModal}
        onClickConfirm={signApplication}
        isLoading={isLoadingUpdateEnvelope || isSubmittingRental.components}
      />
    </>
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(EnvelopeSign)
