import React, { useEffect, useRef } from "react"
import { Box } from "@mui/material"
import TextInput from "@rentspree/component-2023.components.atoms.text-input"
import Typography from "@rentspree/component-2023.components.atoms.typography"
import { Formik, Form } from "formik"

import { COLOR } from "styles/settings"

import {
  SIGNATURE_MODAL_CONSENT_TEXT,
  SIGN_MODAL_MODE,
  SIGNATURE_TYPE,
} from "./constant"
import PreviewSignature from "../../../components/envelope/modal/preview-signature"
import { validateTextSignature } from "../form-schema"

export const TextSignatureForm = ({
  mode,
  handleSaveSignedSignature,
  handleEditSignedSignature,
  setDrawerPrimaryBtnProps,
  signatureData,
}) => {
  const submitButton = useRef(null)

  useEffect(
    () => {
      if (submitButton?.current) {
        setDrawerPrimaryBtnProps({
          onClick: () => {
            submitButton.current?.click()
          },
        })
      }
    },
    [submitButton?.current?.name],
  )

  const handleSubmitSignedSignature = values => {
    const signatures = {
      type: SIGNATURE_TYPE.TYPE,
      ...values,
    }
    if (mode === SIGN_MODAL_MODE.NEW) {
      handleSaveSignedSignature(signatures)
    } else {
      handleEditSignedSignature(signatures)
    }
  }

  return (
    <Formik
      initialValues={{
        fullName:
          signatureData?.type === SIGNATURE_TYPE.TYPE
            ? signatureData?.fullName
            : "",
        initials:
          signatureData?.type === SIGNATURE_TYPE.TYPE
            ? signatureData?.initials
            : "",
      }}
      enableReinitialize
      validationSchema={validateTextSignature}
      onSubmit={(values, actions) => {
        handleSubmitSignedSignature(values)
        actions.setSubmitting(false)
      }}>
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
            }}>
            <TextInput
              id="fullName"
              name="fullName"
              type="text"
              label="Full Name"
              value={values.fullName}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!touched.fullName && !!errors.fullName}
            />
            <TextInput
              id="initials"
              name="initials"
              type="text"
              label="Initials"
              value={values.initials}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!touched.initials && !!errors.initials}
            />
            <PreviewSignature
              signature={values.fullName}
              initial={values.initials}
            />
            <Typography
              variant="caption"
              sx={{ margin: 0, color: COLOR.placeholderGrey }}
              data-testid="signedSignatureInfoText">
              {SIGNATURE_MODAL_CONSENT_TEXT}
            </Typography>

            {/** NOTE: This hidden button is intentionally used to trigger the form submit */}
            <button
              data-testid="submit-btn"
              ref={submitButton}
              type="submit"
              style={{ display: "none" }}>
              submit
            </button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
