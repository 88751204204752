/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
import React from "react"
import { Route, Redirect, useParams, useHistory } from "react-router-dom"
import { buildPath, parse } from "@rentspree/path"

import { NODE_ENV, BASE_NAME } from "env"

import { locationAssign } from "utils/call-window"

const ENVELOPE = "/envelope"

export const WITHOUT_MAIN_CONTENT_PATHS = [ENVELOPE]

export const OCCURRING = {
  CURRENT: "current",
  PREVIOUS: "previous",
  NEXT_PREVIOUS: "nextPrevious",
}

export const ROUTE = {
  RENTAL_HOME: "/property-application/:propertyId",
  GUIDE: {
    OLD: "/:propertyId/option/:screeningOptionId", // need to be remove when this long enough to be no longer use
    BASE: "/:screeningRequestId",
    LANDING: "/landing",
    INSTRUCTION: "/instruction",
    RENTAL_APP: "/rental-app",
    SUBMITTED: "/submitted",
    INACTIVE: "/inactive",
    FINISH: "/finish",
    PAYMENT: "/payment",
    PAYMENT_PROCESSING: "/payment/:process(complete|cancel)",
    APPLY_LIMIT: "/apply-limit",
  },
  APPLICANT: {
    BASE: "/applicant",
    APPLICANT_INFO: "/applicant-info",
    ADDITIONAL_APPLICANT: "/additional-applicant",
  },
  APPLICATION: {
    BASE: "/rental-app",
    PERSONAL: {
      BASE: "/personal",
      PERSONAL_INFO: "/personal-info",
      GOV_ID: "/government-id",
      EMERGENCY: "/emergency-contact",
    },
    RESIDENCE: {
      BASE: "/residence",
      DEFAULT: "/residence/current",
      SELECTED: "/:selected(rental|owner|none)",
      OCCURRING: "/residence/:occurring(current|previous|nextPrevious)",
      RENTAL_BASE: "",
    },
    OCCUPATION: {
      BASE: "/occupation",
      DEFAULT: "/occupation/current",
      SELECTED: "/:selected(rental|owner|none)",
      OCCURRING: "/occupation/:occurring(current|previous)",
    },
    REFERENCE: {
      BASE: "/reference",
      BANK: "/bank",
      CREDIT: "/credit",
      RELATIVE: "/relative",
      PERSONAL: "/personal",
    },
    ADDITIONAL_INFORMATION: {
      BASE: "/additional-info",
    },
  },
  APPLICATION_V2: {
    BASE: "/v2/rental-app",
    PERSONAL: {
      BASE: "/personal",
      PERSONAL_INFO: "/personal-info",
    },
    RESIDENCE: {
      BASE: "/residence",
      DEFAULT: "/residence/current",
      SELECTED: "/:selected(rental|owner|none)",
      OCCURRING: "/residence/:occurring(current|previous|nextPrevious)",
      RENTAL_BASE: "",
    },
    OCCUPATION: {
      BASE: "/occupation",
      DEFAULT: "/occupation/current",
      SELECTED: "/:selected(rental|owner|none)",
      OCCURRING: "/occupation/:occurring(current|previous)",
    },
    REFERENCE: {
      BASE: "/reference",
      RELATIVE: "/relative",
      PERSONAL: "/personal",
    },
    ADDITIONAL_INFORMATION: {
      BASE: "/additional-info",
    },
  },
  TU_SCREENING: {
    BASE: "/tu-screening",
    TERMS: "/terms",
    INFO: "/info",
    ERROR: "/instruction",
    SSN: "/identity",
    ATTACH: "/attach",
    EXAM: "/verify",
  },
  ENVELOPE: {
    SIGN: `${ENVELOPE}/:envelopeId/sign`,
  },
  NOT_FOUND: "/not-found",
  DASHBOARD_AGENT_REPORT:
    "/dashboard/v2/reports/rental-submissions/:rentalId/all",
  DASHBOARD_RENTER: "/dashboard/overview",
  SIGNUP: "/account/signup",
  LOGIN: "/account/login",
  RENTER_OAUTH: "/account/oauth",
  // RENTAL_APP: "/:propertyId/option/:screeningOptionId/rental-app",
  RENTAL_APP: "/:screeningRequestId/rental-app",
  ALREADY_SUBMITTED: "/:propertyId/submitted",
  APPLY_NOW_LANDING: "/apply-now",
  VERSION: `${BASE_NAME}/version`,
  DOCUMENT_UPLOAD: "/documents",
  INCOME_VERIFICATION: "/income-verification",
}

export const RedirectNotFound = () => (
  <Route
    render={() => {
      if (NODE_ENV === "production" || NODE_ENV === "test") {
        locationAssign("/not-found")
      } else {
        console.log("<===== Not Found =====>") // eslint-disable-line
      }
      return null
    }}
  />
)

export const RedirectNewApplyLink = () => {
  const { screeningOptionId } = useParams()
  const history = useHistory()
  return (
    <Redirect
      to={buildPath(
        `${ROUTE.GUIDE.BASE}`,
        {
          screeningRequestId: screeningOptionId,
        },
        parse(history.location.search),
      )}
    />
  )
}
