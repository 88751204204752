import React, { useCallback, useRef } from "react"
import { Clearfix } from "react-bootstrap"
import { withFormik } from "formik"
import styled from "styled-components"
import {
  Span,
  S24,
  Hr,
  TextInput,
  DateInput,
  FormattedInput,
  USStateDropdown,
  Checkbox,
  Tooltip,
  Alert,
  Icon,
  B14,
} from "@rentspree/component-v2"
import { Col } from "components/layout/bootstrap"
import { FormFooter } from "components/buttons/form-footer"
import ToolTipBody from "components/tooltip/tooltip-body-new"
import ScrollToError from "components/scroll/scroll-to-error"
import Beforeunload from "components/alerts/beforeunload"
import { COLOR } from "styles/settings"
import { trackEvent } from "tracker"
import { LOG_EVENT, EVENT_RENTAL_APP_V3_PAGES } from "tracker/tracker-const"
import { FlexContainer } from "components/guide/rental-step"
import SSNInput from "components/molecules/ssn-input"
import * as Sentry from "@sentry/browser"

import { useGoogleAutoComplete } from "hooks/use-google-autocomplete"
import { AddressAutocomplete } from "components/organisms/address-input"
import { InputWrapper } from "components/shared/form"

import { personalInfoSchema } from "./validation-schema"
import { TU_CONTACT } from "../constants"

export const StyledAlert = styled(Alert)`
  display: flex;
  gap: 10px;
  padding: 10px;
  @media (max-width: 990px) {
    align-items: flex-start;
  }
`

export const InnerForm = props => {
  const {
    actions,
    dirty,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    loading,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    isShowCheckBoxUpdateSSN,
    isDisplayTooltip,
    validateForm,
  } = props

  const { addresses, setAddresses, getAddressDetails } = useGoogleAutoComplete(
    Sentry.captureException,
  )

  const autofillAddress = useCallback(
    address =>
      new Promise(resolve => {
        setFieldValue("streetAddressLineOne", address.streetLine)
        setFieldValue("streetAddressLineTwo", address.secondary)
        setFieldValue("city", address.city)
        setFieldValue("state", address.state)
        setFieldValue("zip", address.zipcode)
        resolve()
      }).then(() => validateForm()),
    [setFieldValue, validateForm],
  )
  const addressTopRef = useRef(null)

  const ReferenceTopAddress = styled.div`
    position: absolute;
  `
  const scrollToTop = () => {
    const address = addressTopRef?.current
    if (address) {
      setTimeout(
        () => address.scrollIntoView({ behavior: "smooth", block: "start" }),
        500,
      )
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ScrollToError {...props} fieldOrder={Object.keys(values)} />
        <Beforeunload isDirty={dirty} />
        <div className="tooltip-personal-info-tu">
          <S24>
            Personal Info
            <span
              id="tu-screening-info-pro-tip"
              role="presentation"
              onMouseEnter={() =>
                trackEvent({
                  ...LOG_EVENT.rental_v3_1.proTipMessage,
                  customProperties: {
                    pages: EVENT_RENTAL_APP_V3_PAGES.PRO_TIP.SCREENING_INFO,
                  },
                })
              }
              onClick={() =>
                trackEvent({
                  ...LOG_EVENT.rental_v3_1.proTipMessageMobile,
                  customProperties: {
                    pages: EVENT_RENTAL_APP_V3_PAGES.PRO_TIP.SCREENING_INFO,
                  },
                })
              }>
              {" "}
              <Tooltip
                title="Pro Tip"
                sizeTitle="14px"
                sizeIcon="16px"
                colorTitle="#3b99fc"
                weightTitle="600"
                bubbleWidth="400px">
                <ToolTipBody
                  header="Pro Tip"
                  body={`If your first or middle name exceeds 50 characters, please contact
                TransUnion ${
                  TU_CONTACT.PHONE
                } for instructions on how it should be entered.`}
                />
              </Tooltip>
            </span>
          </S24>

          <Hr margin="0 0 20px 0px" />
        </div>
        <Clearfix>
          <Col md={4} sm={4}>
            <TextInput
              id="tuFirstNameInput"
              name="firstName"
              value={values.firstName}
              label="Legal First Name"
              isRequired
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && errors.firstName}
              disabled={loading.components}
              autoComplete="section-personalInfo-first-name given-name"
            />
          </Col>
          <Col md={4} sm={4}>
            <TextInput
              id="tuMiddleNameInput"
              name="middleName"
              value={values.middleName}
              label="Legal Middle Name (Not Initial)"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.middleName && errors.middleName}
              disabled={loading.components}
              autoComplete="section-personalInfo-middle-name additional-name"
            />
          </Col>
          <Col md={4} sm={4}>
            <TextInput
              id="tuLastNameInput"
              name="lastName"
              value={values.lastName}
              label="Legal Last Name"
              isRequired
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && errors.lastName}
              disabled={loading.components}
              autoComplete="section-personalInfo-last-name family-name"
            />
          </Col>
        </Clearfix>
        <Clearfix>
          <Col md={4} sm={4}>
            <DateInput
              id="tuDobInput"
              name="dob"
              value={values.dob}
              formatType="date"
              label="Date of birth"
              isRequired
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.dob && errors.dob}
              disabled={loading.components}
              autoComplete="section-personalInfo-birthday bday"
            />
          </Col>
          <Col md={4} sm={4}>
            <FormattedInput
              id="tuHomePhoneNumberInput"
              name="homePhoneNumber"
              value={values.homePhoneNumber}
              formatType="phone"
              label="Mobile phone no."
              isRequired
              onValueChange={vals => {
                setFieldValue("homePhoneNumber", vals.value)
              }}
              onBlur={handleBlur}
              error={touched.homePhoneNumber && errors.homePhoneNumber}
              disabled={loading.components}
              autoComplete="section-personalInfo-phone tel"
            />
          </Col>
        </Clearfix>
        <Clearfix>
          <Col md={6}>
            <SSNInput
              id="tuSSNInput"
              name="ssn"
              value={values.ssn}
              label="SSN / ITIN"
              isRequired
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabled={loading.components}
              labelChildren={
                isDisplayTooltip && (
                  <Tooltip
                    title=""
                    bubbleWidth="250px"
                    color="#4a4a4a"
                    iconName="fa-info-circle"
                    position="CUSTOM"
                    positionCustom={{
                      transform: "translate(10%, -60%)",
                      left: "-5px",
                      top: "50%",
                    }}>
                    <ToolTipBody
                      customStyle="padding: 5px 0px 0px 0px;"
                      body="TransUnion is unable to generate screening reports without an SSN/ITIN and a U.S. address.
                  Please contact the requesting agent/owner if you cannot provide either of these."
                    />
                  </Tooltip>
                )
              }
            />
          </Col>
          <Col md={6}>
            <SSNInput
              id="tuSSNConfirmInput"
              name="ssnConfirm"
              value={values.ssnConfirm}
              label="Confirm SSN / ITIN"
              isRequired
              onBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabled={loading.components}
            />
          </Col>
        </Clearfix>
        {isShowCheckBoxUpdateSSN && (
          <Clearfix>
            <Col md={12}>
              <FlexContainer
                margin="-10px 0 0 -4px"
                mMargin="-5px 0 0 -5px"
                direction="row"
                justify="left">
                <Checkbox
                  name="isUpdateSSN"
                  id="isUpdateSSN"
                  label="Update SSN / ITIN with TransUnion"
                  fontSize="16px"
                  simple
                  isSelected={values.isUpdateSSN}
                  onClick={() =>
                    setFieldValue("isUpdateSSN", !values.isUpdateSSN)
                  }
                />
                {isDisplayTooltip && (
                  <Tooltip
                    title=""
                    bubbleWidth="250px"
                    color="#4a4a4a"
                    iconName="fa-info-circle"
                    position="CUSTOM"
                    positionCustom={{
                      transform: "translate(10%, -60%)",
                      left: "-5px",
                      top: "50%",
                    }}>
                    <ToolTipBody
                      customStyle="padding: 5px 0px 0px 0px;"
                      body="TransUnion is unable to generate screening reports without an SSN/ITIN and a U.S. address.
                  Please contact the requesting agent/owner if you cannot provide either of these."
                    />
                  </Tooltip>
                )}
              </FlexContainer>
            </Col>
          </Clearfix>
        )}
        {!isDisplayTooltip && (
          <Col md={12} mb="40px">
            <StyledAlert infoGray>
              <Icon name="MenuSuggest" size="lg" />
              <B14 m0>
                TransUnion requires an SSN/ITIN to verify your identity.
                RentSpree does not store this data.
              </B14>
            </StyledAlert>
          </Col>
        )}
        <S24>Most recent address where you lived 2 or more years</S24>
        <Hr margin="0 0 20px 0" />
        <Clearfix>
          <ReferenceTopAddress id="ref-address" ref={addressTopRef} />
          <Col md={8}>
            <InputWrapper bgTransparent>
              <AddressAutocomplete
                id="dashboardNewPropertyStreet"
                className="newPropertyInput"
                name="streetAddressLineOne"
                label="Address Line 1"
                labelChildren={
                  <Span size="14px" color={COLOR.lightGray} ml="5">
                    (Street and number)
                  </Span>
                }
                value={values.streetAddressLineOne}
                onDebounceChange={val => setAddresses(val)}
                onValueChange={val => {
                  setFieldValue("streetAddressLineOne", val.trim())
                }}
                onBlur={() => {
                  setFieldTouched("streetAddressLineOne", true)
                }}
                onSelectOption={autofillAddress}
                onFocus={scrollToTop}
                error={
                  touched.streetAddressLineOne && errors.streetAddressLineOne
                }
                options={addresses}
                isRequired
                getAddressDetails={getAddressDetails}
              />
            </InputWrapper>
          </Col>
        </Clearfix>
        <Clearfix>
          <Col md={8}>
            <TextInput
              id="tuAddressLineTwo"
              name="streetAddressLineTwo"
              value={values.streetAddressLineTwo}
              label="Address Line 2"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.streetAddressLineTwo && errors.streetAddressLineTwo
              }
              disabled={loading.components}
              labelChildren={
                <Span size="14px" color={COLOR.lightGray} ml="5">
                  (Apartment, suite, unit, building, floor, etc.)
                </Span>
              }
              autoComplete="section-recentAddress address-line2"
            />
          </Col>
        </Clearfix>
        <Clearfix>
          <Col md={4}>
            <TextInput
              id="tuCityInput"
              name="city"
              value={values.city}
              label="City"
              isRequired
              onChange={handleChange}
              onBlur={e => {
                e.target.value = e.target.value.trim()
                setFieldValue("city", e.target.value)
                handleBlur(e)
              }}
              error={touched.city && errors.city}
              disabled={loading.components}
              autoComplete="section-recentAddress address-level2"
            />
          </Col>
          <Col md={4}>
            <USStateDropdown
              name="state"
              id="tuStateInput"
              onChange={val => setFieldValue("state", val)}
              onBlur={() => setFieldTouched("state", true)}
              value={values.state}
              error={touched.state && errors.state}
              label="State"
              isRequired
              disabled={loading.components}
              autoComplete="section-recentAddress address-level1"
            />
          </Col>
          <Col md={2}>
            <FormattedInput
              formatType="zipcode"
              id="tuZipcodeInput"
              name="zip"
              label="Zip Code"
              value={values.zip}
              onValueChange={vals => {
                setFieldValue("zip", vals.value)
              }}
              onBlur={handleBlur}
              error={touched.zip && errors.zip}
              isRequired
              disabled={loading.components}
              autoComplete="section-recentAddress postal-code"
            />
          </Col>
        </Clearfix>
        <FormFooter
          cancelBtn={{
            id: "tuInfoFormCancelBtn",
            onClick: () => actions.redirect(),
            disabled: loading.components,
          }}
          continueBtn={{
            id: "tuInfoFormContinueBtn",
            disabled: loading.components,
            loading: loading.components,
            loaded: loading.success,
            text: "Continue",
          }}
        />
      </form>
    </div>
  )
}

const Form = withFormik({
  enableReinitialize: true,
  displayName: "tuInfoForm",
  mapPropsToValues: props => personalInfoSchema(props).mapper,
  validationSchema: () => personalInfoSchema().schema,
  handleSubmit: (values, { props }) => {
    props.actions.setIsShowReviewFlag(values)
  },
})(InnerForm)

export default Form
