import React from "react"
import PropTypes from "prop-types"

import Dialog from "@rentspree/component-2023.components.atoms.dialog"
import Button from "@rentspree/component-2023.components.atoms.button"
import DialogTitle from "@rentspree/component-2023.components.atoms.dialog-title"
import { DialogActions, DialogContentText, DialogContent } from "@mui/material"
import { LoadingButton } from "../atom/loading-button"

const ConfirmationModal = ({
  isOpen,
  canBeClosedByClickOverlay,
  title,
  content,
  confirmBtnTxt,
  cancelBtnTxt,
  onClickConfirm,
  onClose,
  isLoading,
}) => (
  <Dialog
    size="large"
    open={isOpen}
    onClose={onClose}
    shouldCloseOnOverlayClick={canBeClosedByClickOverlay}>
    <DialogTitle handleOnClose={onClose}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        id="cancelBtn"
        data-testid="cancel-btn"
        sourceSansPro
        disabled={isLoading}
        onClick={onClose}>
        {cancelBtnTxt}
      </Button>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        id="confirmBtn"
        data-testid="confirm-btn"
        onClick={onClickConfirm}>
        <LoadingButton isLoading={isLoading}>{confirmBtnTxt}</LoadingButton>
      </Button>
    </DialogActions>
  </Dialog>
)

ConfirmationModal.defaultProps = {
  title: "Confimation modal title",
  content: "Confimation modal content",
  confirmBtnTxt: "Confirm",
  cancelBtnTxt: "Cancel",
  modalSize: "small",
  canBeClosedByClickOverlay: true,
  btnNextMinWidth: "110",
  isLoading: false,
}

ConfirmationModal.propsType = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  confirmBtnTxt: PropTypes.string,
  cancelBtnTxt: PropTypes.string,
  modalSize: PropTypes.oneOf(["small", "medium", "large"]),
  canBeClosedByClickOverlay: PropTypes.bool,
  btnNextMinWidth: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onClickConfirm: PropTypes.func,
}

export default ConfirmationModal
