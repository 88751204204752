import { object } from "yup"
import _ from "lodash"
import {
  validateLength,
  validateDob,
  getInvalidTUNameMessage,
  getInvalidAddressLineTwoMessage,
  getInvalidCityMessage,
  validatePhone,
  validateState,
  validateZipCode,
  getSSNMismatchErrorMessage,
  validateSSN,
  validateMismatch,
  TU_REGEX,
  getInvalidStartingSSN,
  validateAddressLineOne,
} from "constants/validations"

export const validateSchema = object({
  firstName: validateLength(true, 2, 50).matches(
    TU_REGEX.name,
    getInvalidTUNameMessage,
  ),
  middleName: validateLength(false, 0, 50).matches(
    TU_REGEX.name,
    getInvalidTUNameMessage,
  ),
  lastName: validateLength(true, 2, 50).matches(
    TU_REGEX.name,
    getInvalidTUNameMessage,
  ),
  dob: validateDob(true, false),
  homePhoneNumber: validatePhone(true),
  ssn: validateSSN(true)
    .matches(TU_REGEX.ssn, getInvalidStartingSSN)
    .test(
      "SSNMismatch",
      getSSNMismatchErrorMessage,
      validateMismatch("ssnConfirm"),
    ),
  ssnConfirm: validateSSN(true)
    .matches(TU_REGEX.ssn, getInvalidStartingSSN)
    .test("SSNMismatch", getSSNMismatchErrorMessage, validateMismatch("ssn")),
  streetAddressLineOne: validateAddressLineOne(true, 2, 100),
  streetAddressLineTwo: validateLength(false, 0, 100).matches(
    TU_REGEX.addressLine2,
    getInvalidAddressLineTwoMessage,
  ),
  city: validateLength(true, 3, 50).matches(
    TU_REGEX.city,
    getInvalidCityMessage,
  ),
  state: validateState(),
  zip: validateZipCode(),
})

export const personalInfoSchema = props => ({
  mapper: {
    firstName:
      _.get(props, "tuRenter.firstName") ||
      _.get(props, "application.firstName") ||
      "",
    middleName:
      _.get(props, "tuRenter.middleName") ||
      _.get(props, "application.middleName") ||
      "",
    lastName:
      _.get(props, "tuRenter.lastName") ||
      _.get(props, "application.lastName") ||
      "",
    dob:
      _.get(props, "tuRenter.dateOfBirth") ||
      _.get(props, "application.dateOfBirth", ""),
    homePhoneNumber:
      _.get(props, "tuRenter.homePhoneNumber") ||
      _.get(props, "usNationalNumber", ""),
    ssn: "",
    ssnConfirm: "",
    streetAddressLineOne:
      _.get(props, "tuRenter.streetAddressLineOne") ||
      _.get(props, "address.street", ""),
    streetAddressLineTwo: _.get(props, "tuRenter.streetAddressLineTwo", ""),
    city: _.get(props, "tuRenter.city") || _.get(props, "address.city", ""),
    state: _.get(props, "tuRenter.state") || _.get(props, "address.state", ""),
    zip: _.get(props, "tuRenter.zip") || _.get(props, "address.zipCode", ""),
    isUpdateSSN: false,
  },
  schema: validateSchema,
})
