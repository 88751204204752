import get from "lodash/get"
import { object } from "yup"

import {
  validateLength,
  eSignRecipientListValidation,
} from "constants/validations"

export const stepRecipients = props => ({
  mapper: {
    recipients: get(props, "recipients"),
  },
  validation: object().shape({
    recipients: eSignRecipientListValidation(),
  }),
})

export const textSignSignature = props => {
  const type = get(props, "signatureData.type")
  return {
    mapper: {
      fullName: type === "type" ? get(props, "signatureData.fullName") : "",
      initials: type === "type" ? get(props, "signatureData.initials") : "",
    },
    validation: object().shape({
      fullName: validateLength(true, 2, 50),
      initials: validateLength(true, 1, 50),
    }),
  }
}

export const voidFrom = () => ({
  mapper: {
    voidReason: "",
  },
  validation: object().shape({
    voidReason: validateLength(true, 4, 255),
  }),
})

export const validateTextSignature = object().shape({
  fullName: validateLength(true, 2, 50),
  initials: validateLength(true, 1, 50),
})
