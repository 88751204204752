import qs from "qs"
import isEmpty from "lodash/isEmpty"

export const parse = (str = "") =>
  !isEmpty(str) ? qs.parse(str.replace(/^\?/, "")) : {}

export function urlParser(url) {
  const { hostname, origin, port, protocol } = new URL(url)

  const domain = hostname.match(/([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+$/) || [""]
  const subDomainPrefix = hostname.replace(/\..*/, "").replace(hostname, "") // TODO_EMILY: this only captures, for example, the prefixes "www" or "dev". Does not capture the whole subdomain of "dev.rentspree.dev"

  return {
    origin,
    hostname,
    domain:
      subDomainPrefix !== "www"
        ? hostname
        : domain[0].replace(`${subDomainPrefix}.`, ""),
    subdomain: subDomainPrefix,
    protocol: `${protocol}//`, // TODO_EMILY: protocol shouldn't have "//"" on it
    port,
    paramQuery: url.replace(origin, ""), // TODO_EMILY: possibly can replace with whatwgUrl.search or whatwgUrl.searchParams, depending on how it's used
  }
}
