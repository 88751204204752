import React from "react"
import { Box } from "@mui/material"
import Typography from "@rentspree/component-2023.components.atoms.typography"

import { COLOR } from "styles/settings"

import {
  SIGNATURE_MODAL_CONSENT_TEXT,
  SIGNATURE_TYPE,
  SIGN_MODAL_MODE,
} from "./constant"
import { DrawInput } from "../../../components/envelope/modal/draw-input"

export const DrawSignatureForm = ({
  uploadSignedSignature,
  uploadEditedSignature,
  mode,
  envelopeId,
  userToken,
  setDrawerPrimaryBtnProps,
}) => {
  const signatureRef = React.useRef(null)
  const initialsRef = React.useRef(null)
  const [signatureErr, setSignatureErr] = React.useState(false)
  const [initialsErr, setInitialsErr] = React.useState(false)

  const validate = () => {
    setSignatureErr(signatureRef?.current.isEmpty())
    setInitialsErr(initialsRef?.current.isEmpty())
    return !signatureRef?.current.isEmpty() && !initialsRef?.current.isEmpty()
  }
  const handleSubmit = () => {
    if (!validate()) {
      return
    }
    const drawnSignature = {
      type: SIGNATURE_TYPE.DRAW,
      fullName: signatureRef?.current.getCanvas().toDataURL(),
      initials: initialsRef?.current.getCanvas().toDataURL(),
      envelopeId,
      userToken,
    }
    if (mode === SIGN_MODAL_MODE.NEW) {
      uploadSignedSignature(drawnSignature)
    } else {
      uploadEditedSignature(drawnSignature)
    }
  }
  React.useEffect(() => {
    setDrawerPrimaryBtnProps({
      onClick: () => {
        handleSubmit()
      },
    })
  }, [])

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
        }}>
        <DrawInput
          setRef={ref => {
            signatureRef.current = ref
          }}
          dWidth="100%"
          dHeight="100px"
          isRequired
          labelText="Draw your signature"
          isError={signatureErr}
          onClear={() => {
            signatureRef?.current.clear()
          }}
        />
        <DrawInput
          setRef={ref => {
            initialsRef.current = ref
          }}
          dWidth="100%"
          dHeight="100px"
          isRequired
          labelText="Draw your initials"
          isError={initialsErr}
          onClear={() => {
            initialsRef?.current.clear()
          }}
        />
      </Box>
      <Typography
        variant="caption"
        sx={{ margin: 0, color: COLOR.placeholderGrey }}
        data-testid="signedSignatureInfoText">
        {SIGNATURE_MODAL_CONSENT_TEXT}
      </Typography>
    </>
  )
}
