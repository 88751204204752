import React from "react"
import { Box, CircularProgress } from "@mui/material"

export const LoadingButton = ({ isLoading, children }) => (
  <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
    {isLoading && (
      <CircularProgress
        data-testid="circular-loading"
        color="inherit"
        size={16}
      />
    )}
    {children}
  </Box>
)
