import { fromJS } from "immutable"
import * as appAction from "containers/application/constants"
import * as wrapperAction from "containers/wrapper/constants"
import * as instructionAction from "containers/rental-submission/rental-instruction/constants"
import * as rentalAction from "containers/rental-submission/constants"
import * as applicantAction from "containers/applicant-info/constants"
import * as tuAction from "containers/tu-screening/constants"
import * as guideAction from "containers/rental-submission/rental-guide/constants"
import * as paymentAction from "containers/rental-submission/rental-payment/constants"
import * as tuAgreementActions from "containers/tu-screening/terms/constants"
import * as documentUploadActions from "containers/document-upload/constants"
import * as autosaveAction from "containers/autosave-form/constants"
import { HIDE_LOADING, SHOW_COMPONENTS_LOADING } from "./constants"

export const initialState = fromJS({
  components: false,
  success: false,
  full: false,
})

function loadingReducer(state = initialState, action) {
  let nextState = null
  switch (action.type) {
    // set components loading
    case SHOW_COMPONENTS_LOADING:
    case appAction.SAVE_APPLICATION_REQUEST:
    case instructionAction.START_NEW_RENTAL_REQUEST:
    case applicantAction.SAVE_APPLICANT_INFO_REQUEST:
    case tuAction.ACCEPT_TERMS_REQUEST:
    case tuAction.START_NEW_SCREENING_REQUEST:
    case tuAction.EXAM_EVALUATE_REQUEST:
    case tuAction.ATTACH_REPORT_REQUEST:
    case appAction.VALIDATION_APPLICATION_CALL:
    case paymentAction.SUBMIT_PAYMENT_REQUEST:
    case guideAction.SUBMIT_RENTAL_REQUEST:
      nextState = state.set("components", true)
      return nextState
    // set finished state
    case wrapperAction.SET_SUCCESS:
    case guideAction.SUBMIT_RENTAL_SUCCESS:
      nextState = initialState.set("success", true)
      return nextState
    // set full loading
    case appAction.GET_APPLICATION_REQUESTED:
    case rentalAction.GET_RENTAL_SUBMISSION_REQUEST:
    case rentalAction.GET_RENTAL_WITH_CO_APPLICANT_REQUEST:
    case wrapperAction.GET_SCREENING_REQUEST_REQUEST:
    case wrapperAction.GET_PROFILE_REQUEST:
    case tuAction.GET_TU_DATA_REQUEST:
    case tuAction.EXAM_RETRIEVE_REQUEST:
    case paymentAction.GET_PAYMENT_REQUEST:
    case tuAgreementActions.GET_TU_AGREEMENT_CONTENT_REQUESTED:
    case documentUploadActions.GET_DRAFT_DOCUMENT_REQUEST:
      return state.set("full", true)
    // hide loading
    case HIDE_LOADING:
    case tuAction.EXAM_RETRIEVE_SUCCESS:
      return state.merge({ full: false, components: false, success: false })
    // set initial when success/failed
    case applicantAction.SAVE_APPLICANT_INFO_SUCCESS:
    case applicantAction.SAVE_APPLICANT_INFO_FAILED:
    case appAction.SAVE_APPLICATION_SUCCESS:
    case appAction.SAVE_APPLICATION_FAILED:
    case instructionAction.START_NEW_RENTAL_SUCCESS:
    case instructionAction.START_NEW_RENTAL_FAILED:
    case tuAction.ACCEPT_TERMS_SUCCESS:
    case tuAction.ACCEPT_TERMS_FAILED:
    case tuAction.START_NEW_SCREENING_SUCCESS:
    case tuAction.START_NEW_SCREENING_FAILED:
    case tuAction.EXAM_EVALUATE_SUCCESS:
    case tuAction.EXAM_EVALUATE_FAILED:
    case tuAction.ATTACH_REPORT_SUCCESS:
    case tuAction.ATTACH_REPORT_FAILED:
    case appAction.VALIDATION_APPLICATION_DONE:
    case paymentAction.SUBMIT_PAYMENT_FAILED:
    case guideAction.SUBMIT_RENTAL_FAILED:
    case wrapperAction.REDIRECT_RENTAL_SUBMISSION_CALL:
    case tuAgreementActions.GET_TU_AGREEMENT_CONTENT_SUCCESS:
    case tuAgreementActions.GET_TU_AGREEMENT_CONTENT_FAILED:
    case autosaveAction.CANCEL_RENTAL_APPLICATION_AUTOSAVE:
    case autosaveAction.CANCEL_RENTAL_SPECIFICS_AUTOSAVE:
    case autosaveAction.RENTAL_APPLICATION_QUEUE_AUTOSAVE_SUCCESS:
    case autosaveAction.RENTAL_SPECIFICS_QUEUE_AUTOSAVE_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default loadingReducer
