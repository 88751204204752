import React from "react"
import { compose } from "redux"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import { withRouter } from "react-router-dom"
import { generateAddress } from "@rentspree/helper"
import styled from "styled-components"
import { T36, Hr, BlueGradientButton } from "@rentspree/component-v2"
import Amplitude from "amplitude-js"

import { InstructionWrapper } from "components/layout"
import {
  InstructionList,
  ApplicationFeeAndMessage,
} from "components/instruction"
import { RentalDetail } from "components/guide/rental-detail"
import { FlexContainer } from "components/guide/rental-step"
import { tracker, withTracker } from "tracker"
import {
  RENTAL_PAGE_NAME,
  RENTAL_INSTRUCTION_EVENT,
} from "tracker/tracker-const"

import { withConnect, withSaga, withReducer } from "./connect"

const RentalDetailTitle = styled(T36)`
  margin-top: "50px";
  margin-bottom: "30px";
`

export const OrderList = styled.ol`
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 30px;
  li {
    margin-bottom: 2px;
  }
`

export const UnorderList = styled.ul`
  padding-left: 20px;
  margin-top: 16px;
  margin-bottom: 30px;
  li {
    margin-bottom: 2px;
  }
`

export class InstructionPage extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      isEmpty(prevProps.screeningOption) &&
      !isEmpty(this.props.screeningOption)
    ) {
      tracker.trackEvent(RENTAL_INSTRUCTION_EVENT.ENTER_PAGE.EVENT_NAME, {
        [RENTAL_INSTRUCTION_EVENT.ENTER_PAGE.EVENT_PROPS
          .SCREENING_OPTION_PREMIUM]: this.props.screeningOption.premium,
      })
    }
  }

  startNewRental = () => {
    Amplitude.getInstance().setUserProperties({
      partial_98: "experiment",
    })

    const { actions, screeningRequestId } = this.props
    actions.startNewRental(screeningRequestId)
  }

  render() {
    const { screeningOption, property, loading, price, multiShare } = this.props

    const { isMultiShareActive } = multiShare
    const fee = price?.total
    const address = generateAddress(property)

    const isRenterPay = isEqual(get(screeningOption, "payerType"), "renter")
    const isIncludeCreditReport = get(screeningOption, "credit_report")
    const isRenterPayAndIncludeCreditReport =
      isRenterPay && isIncludeCreditReport

    const applicationFee = isMultiShareActive ? 0 : fee

    const isShowApplicationFee = isRenterPay || isMultiShareActive

    const shouldShowPaymentOption =
      isRenterPayAndIncludeCreditReport && !isMultiShareActive

    return (
      <InstructionWrapper>
        <RentalDetailTitle>Submit Your Application</RentalDetailTitle>
        <RentalDetail address={address} mMargin="10px 0 30px" />
        <Hr margin="10px 0 19px 0" />
        <InstructionList shouldShowPaymentOption={shouldShowPaymentOption} />
        <Hr margin="20px 0 20px 0" />
        <FlexContainer width="100%" alignItems="flex-start">
          <ApplicationFeeAndMessage
            fee={applicationFee}
            isShowApplicationFee={isShowApplicationFee}
            multiShare={multiShare}
            screeningOption={screeningOption}
          />
          <FlexContainer
            width="100%"
            alignItems="flex-start"
            margin="0 0 0 0"
            mMargin="0 0 20px 0">
            <BlueGradientButton
              small
              shadow
              hasIcon
              semiBold
              loading={loading.components}
              loaded={loading.success}
              text="Apply Now"
              onClick={this.startNewRental}
              minWidth={155}
              mWidth="100%"
              id="rentalApplyBtn"
            />
          </FlexContainer>
        </FlexContainer>
      </InstructionWrapper>
    )
  }
}

export default compose(
  withRouter,
  withConnect,
  withReducer,
  withSaga,
)(withTracker(InstructionPage, tracker, RENTAL_PAGE_NAME.INSTRUCTION))
