import React, { useState } from "react"
import { Box } from "@mui/material"
import Button from "@rentspree/component-2023.components.atoms.button"
import ButtonGroup from "@rentspree/component-2023.components.atoms.button-group"
import Drawer from "@rentspree/component-2023.components.organisms.drawer"

import { LoadingButton } from "components/atom/loading-button"

import {
  SIGNATURE_FORM_SUBMIT_TEXT,
  SIGNATURE_FORM_TITLE_TEXT,
  SIGNATURE_TYPE,
  SIGNATURE_TYPE_LABEL,
  SIGN_MODAL_MODE,
} from "./constant"
import { DrawSignatureForm } from "./new-draw-signature-form"
import { TextSignatureForm } from "./new-text-signature-form"

export const SignatureFormDrawer = ({
  envelopeId,
  userToken,
  drawerConfig,
  handleCloseDrawer,
  handleSaveSignedSignature,
  handleEditSignedSignature,
  uploadSignedSignature,
  uploadEditedSignature,
  isUploadingSignature,
  signatureData,
}) => {
  const [drawerPrimaryBtnProps, setDrawerPrimaryBtnProps] = useState({})
  const [activeTab, setActiveTab] = useState(
    signatureData?.type || SIGNATURE_TYPE.TYPE,
  )
  const nextText =
    drawerConfig.mode === SIGN_MODAL_MODE.NEW
      ? SIGNATURE_FORM_SUBMIT_TEXT.NEW_MODE
      : SIGNATURE_FORM_SUBMIT_TEXT.EDIT_MODE
  const titleText =
    drawerConfig.mode === SIGN_MODAL_MODE.NEW
      ? SIGNATURE_FORM_TITLE_TEXT.NEW_MODE
      : SIGNATURE_FORM_TITLE_TEXT.EDIT_MODE

  return (
    <Drawer
      id="signatureDrawer"
      anchor="right"
      title={titleText}
      open={drawerConfig.show}
      onClose={handleCloseDrawer}
      displayCloseButton
      actionButtonsProps={{
        primaryButtonText: (
          <LoadingButton isLoading={isUploadingSignature}>
            {nextText}
          </LoadingButton>
        ),
        secondaryButtonText: "Cancel",
        secondaryButtonProps: {
          onClick: handleCloseDrawer,
        },
        primaryButtonProps: drawerPrimaryBtnProps,
      }}
      size="small">
      <Box
        className="drawer-content-container"
        sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <ButtonGroup
          data-testid="signatureDrawerHeader"
          variant="outlined"
          size="small">
          <Button
            autoFocus
            disableFocusRipple
            sx={{
              background:
                activeTab === SIGNATURE_TYPE.TYPE ? "#22222229 !important" : "",
            }}
            onClick={() => {
              setActiveTab(SIGNATURE_TYPE.TYPE)
            }}>
            {SIGNATURE_TYPE_LABEL.TYPE}
          </Button>
          <Button
            sx={{
              background:
                activeTab === SIGNATURE_TYPE.DRAW ? "#22222229 !important" : "",
            }}
            onClick={() => {
              setActiveTab(SIGNATURE_TYPE.DRAW)
            }}>
            {SIGNATURE_TYPE_LABEL.DRAW}
          </Button>
        </ButtonGroup>
        {activeTab === SIGNATURE_TYPE.TYPE ? (
          <TextSignatureForm
            signatureData={signatureData}
            mode={drawerConfig.mode}
            handleSaveSignedSignature={handleSaveSignedSignature}
            handleEditSignedSignature={handleEditSignedSignature}
            setDrawerPrimaryBtnProps={setDrawerPrimaryBtnProps}
          />
        ) : (
          <DrawSignatureForm
            mode={drawerConfig.mode}
            envelopeId={envelopeId}
            userToken={userToken}
            uploadSignedSignature={uploadSignedSignature}
            uploadEditedSignature={uploadEditedSignature}
            setDrawerPrimaryBtnProps={setDrawerPrimaryBtnProps}
          />
        )}
      </Box>
    </Drawer>
  )
}
