import { object } from "yup"
import {
  getSSNMismatchErrorMessage,
  validateSSN,
  validateMismatch,
  TU_REGEX,
  getInvalidStartingSSN,
} from "constants/validations"

export const ssnSchema = () => ({
  mapper: {
    ssn: "",
    ssnConfirm: "",
  },
  schema: object({
    ssn: validateSSN(true)
      .matches(TU_REGEX.ssn, getInvalidStartingSSN)
      .test(
        "SSNMismatch",
        getSSNMismatchErrorMessage,
        validateMismatch("ssnConfirm"),
      ),
    ssnConfirm: validateSSN(true)
      .matches(TU_REGEX.ssn, getInvalidStartingSSN)
      .test("SSNMismatch", getSSNMismatchErrorMessage, validateMismatch("ssn")),
  }),
})
