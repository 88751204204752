import { createSelector } from "reselect"

import { initialState } from "./reducer"

export const selectScreeningOption = state =>
  state.get("screeningOption", initialState)

export const makeSelectScreeningOption = () =>
  createSelector(selectScreeningOption, substate => substate.toJS())

export const makeSelectScreeningOptionCreatedAt = createSelector(
  selectScreeningOption,
  substate => substate.toJS().createdAt,
)
