import React, { useEffect, useState } from "react"
import Menu from "@rentspree/component-2023.components.atoms.menu"
import MenuItem from "@rentspree/component-2023.components.atoms.menu-item"

export const MenuDropDown = ({ popper, dropdownItems = [], onClose }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(
    () => {
      setIsOpen(!!popper)
      if (popper) {
        const refNode = document.querySelector(`[id='${popper}']`)
        setAnchorEl(refNode)
      }
    },
    [popper],
  )

  const clearState = () => {
    setAnchorEl(null)
    setIsOpen(false)
  }

  if (!popper) {
    return null
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      autoFocus={false}
      onClose={() => {
        onClose()
        clearState()
      }}
      data-testid="menu-drop-down">
      {dropdownItems.map(({ label, onClick }) => (
        <MenuItem
          key={`dropdown-${label}-item`}
          label={label}
          onClick={() => {
            onClick()
            clearState()
          }}
        />
      ))}
    </Menu>
  )
}
