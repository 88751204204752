import styled from "styled-components"
import Button from "@rentspree/component-2023.components.atoms.button"

export const SignFlagWrapper = styled.div`
  display: ${props => (props.large ? "block" : "inline-block")};
`

export const StyledArrowButton = styled(Button)`
  padding: 0 20px 0 0 !important;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor} !important`};
  ${({ width }) => width && `width: ${width} !important`};
  ${({ height }) => height && `height: ${height} !important`};
  ${({ bodyLength }) =>
    bodyLength &&
    `clip-path: polygon(0% 0%, ${bodyLength} 0%, 100% 50%, ${bodyLength} 100%, 0% 100%)`};
`
