import { MAIN_URL, RENTSPREE_DEV_SUBDOMAIN } from "../env"
import { urlParser } from "./query-parser"

const { domain } = urlParser(MAIN_URL || `https://${RENTSPREE_DEV_SUBDOMAIN}`)

/**
 * Build CDN URL of image (in `static-files`)
 * @param {string} pathname
 * @returns {string} CDN URL of image
 */
export const getCDNImage = pathname => {
  if (!pathname) {
    return ""
  }
  return `https://cdn.${domain}/static-files${
    pathname.charAt(0) === "/" ? pathname : `/${pathname}`
  }`
}

/**
 * Resize Image with Image proxy
 * @param {string} url URL of image
 * @param {object} size size of image
 * @param {string|number} size.width
 * @param {string|number} size.height
 * @returns {string} Resized URL with Image proxy
 */
export const resizeWithImageProxy = (url, size) => {
  if (!url) {
    return ""
  }
  if (size) {
    let resizedUrl = `https://image-proxy.${domain}/resize`
    if (size.width)
      resizedUrl = resizedUrl.concat(
        // get only number in string
        `/w:${
          typeof size.width === "string"
            ? size.width.replace(/\D/g, "")
            : size.width
        }`,
      )
    if (size.height)
      resizedUrl = resizedUrl.concat(
        // get only number in string
        `/h:${
          typeof size.height === "string"
            ? size.height.replace(/\D/g, "")
            : size.height
        }`,
      )
    return resizedUrl.concat(`/plain/${url}`)
  }
  return url
}
