import _, { isEmpty } from "lodash"
import { buildPath } from "@rentspree/path"
import { put, call, select, takeLatest } from "redux-saga/effects"
import urlJoin from "url-join"
import message from "constants/error-messages"

import { tracker } from "tracker"
import { TU_EVENT } from "tracker/tracker-const"
import { ROUTE } from "containers/router/constants"
import { redirect, setSuccess } from "containers/wrapper/actions"
import { apiInstance } from "utils/api-interceptor"
import {
  selectSsn,
  selectStatusTURenter,
} from "containers/tu-screening/selectors"
import { redirectTuError } from "containers/tu-screening/actions"
import {
  showErrorAlertCall,
  clearErrorAlertCall,
} from "containers/error/constants"
import { selectRentalId } from "containers/rental-submission/selectors"
import { CREDIT_REPORT_STATUS } from "containers/rental-submission/constants"

import * as examConst from "../constants"
import { clearSSN } from "../ssn/constants"

export const getExamApi = ({ rentalId, ssn }) =>
  apiInstance.post(
    buildPath(examConst.POST_EXAM_RETRIEVE, {
      rentalId,
    }),
    { ssn },
  )

export function* getExam() {
  const isScrollTop = true
  const ssn = yield select(selectSsn)
  const rentalId = yield select(selectRentalId)
  const ssnPage = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.SSN)
  if (_.isEmpty(ssn)) {
    const infoPage = urlJoin(ROUTE.TU_SCREENING.BASE, ROUTE.TU_SCREENING.INFO)
    const status = yield select(selectStatusTURenter)
    if (status === examConst.TU_RENTER_STATUS.PASS_EXAM) {
      yield put(redirect(ssnPage))
    } else {
      yield put(redirect(infoPage))
    }
  } else {
    yield put(examConst.examRetrieveRequest())
    try {
      const creditReport = yield call(getExamApi, {
        rentalId,
        ssn,
      })
      const creditReportStatus = _.get(creditReport, "status", "")
      const incompleteVerificationStatus = [
        CREDIT_REPORT_STATUS.CREATED,
        CREDIT_REPORT_STATUS.EXAM,
        CREDIT_REPORT_STATUS.ERROR,
      ]
      const shouldRedirectToGuidePage =
        !isEmpty(creditReportStatus) &&
        !incompleteVerificationStatus.includes(creditReportStatus)

      yield put(examConst.examRetrieveSuccess(creditReport))
      yield put(clearErrorAlertCall())
      if (shouldRedirectToGuidePage) {
        yield put(examConst.repeatedExamRetrieveCall(creditReport))
        yield put(setSuccess())
        yield put(redirect())
      }
      if (creditReportStatus === CREDIT_REPORT_STATUS.ERROR) {
        yield put(redirectTuError())
      }
    } catch (e) {
      yield put(examConst.examRetrieveFailed(e))
      // Redirect to ssn page when failed ssn validation
      if (_.get(e, "status") === 422) {
        yield put(clearSSN())
        yield put(redirect(ssnPage))
        yield put(
          showErrorAlertCall({
            isScrollTop,
            // TODO: should change this error to actual response from api
            message: message.tuExamWrongSSN,
          }),
        )
      }
    }
  }
}

export const evaluateExamApi = (rentalId, exam) =>
  apiInstance.post(buildPath(examConst.POST_EXAM_EVALUATE, { rentalId }), exam)

export function* evaluateExamResponseHandler(creditReport, isScrollTop) {
  yield put(examConst.examEvaluateSuccess(creditReport))

  switch (_.get(creditReport, "status")) {
    case "exam":
      yield put(
        showErrorAlertCall({
          isScrollTop,
          message: message.wrongTuExamAnswers,
        }),
      )
      break
    case "ready":
      yield put(setSuccess())
      yield put(redirect())
      yield call([tracker, "trackEvent"], TU_EVENT.submitTransUnionExam)
      yield call([tracker, "trackEvent"], TU_EVENT.COMPLETE_TU_SCREENING)
      break
    case "error":
      yield put(redirectTuError())
      yield put(clearErrorAlertCall())
      break
    default:
      break
  }
}

export function* evaluateExam(action) {
  const isScrollTop = true
  yield put(examConst.examEvaluateRequest())
  try {
    const rentalId = yield select(selectRentalId)
    const creditReport = yield call(evaluateExamApi, rentalId, action.payload)
    yield call(evaluateExamResponseHandler, creditReport, isScrollTop)
  } catch (e) {
    if (e.status === 403 && e?.data?.code === examConst.REPEATED_EXAM) {
      yield call(
        evaluateExamResponseHandler,
        e.data.response.creditReport,
        isScrollTop,
      )
    }
    yield put(examConst.examEvaluateFailed())
    // Retrieve exam again when error code is fail_exam
    if (
      _.get(e, "status") === 409 &&
      _.get(e, "data.code") === examConst.TU_ERROR_CODE.FAIL_EXAM
    ) {
      yield* getExam()
      yield put(
        showErrorAlertCall({
          isScrollTop,
          message: message.wrongTuExamAnswers,
        }),
      )
    }
  }
}

export function* rootSaga() {
  yield takeLatest(examConst.EXAM_EVALUATE_CALL, evaluateExam)
}

export default rootSaga
