/**
 * Takes simple date configurations transforms them into date configurations with longer-form month names.
 *
 * This util is intended only to format from numbers into longer-form, so it is written to ignore times and timezones.
 *
 * Note: If the input String does not specify a timezone, then the Date constructor will use system time (UNIX) to calculate the date and interpret the current timezone as UTC/GMT
 * @param {string} date - a valid date String (not a Date object)
 * @param {*} options
 * @returns
 */
export const displayDateString = (date, options = {}) => {
  const defaultFormattingOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  }

  // date input is a valid string
  // transform it into a Date Obj
  // return w/ formating

  // if date param is falsy, not a string type, or
  // if it is a string, but it cannot be parsed into a valid Date format
  if (
    !date ||
    typeof date !== "string" ||
    Number.isNaN(new Date(date).valueOf())
  ) {
    throw TypeError(
      "displayDateString expects a valid String parameter that can be formatted to a valid Date",
    )
  }

  // input will most likely be a valid string, so here we can convert it to a Date object for transforming
  const validDate = new Date(date)

  return validDate.toLocaleDateString("en-US", {
    ...defaultFormattingOptions,
    ...options,
  })
}
