import React from "react"
import styled from "styled-components"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import HomeIcon from "@mui/icons-material/Home"
import Button from "@rentspree/component-2023.components.atoms.button"
import { COLOR } from "styles/settings"
import { LoadingButton } from "../atom/loading-button"

const ButtonSetContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Source Sans Pro, sans-serif;
  margin-top: 30px;
  margin-bottom: 40px;
  ${props => props.margin && `margin: ${props.margin}`};
  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const PrevAndNextContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    justify-content: ${props =>
      props.isShowPrev ? "space-between" : "flex-end"};
    border-bottom: 1px solid ${COLOR.borderGrey};
    padding-bottom: 20px;
  }
`

const BackButtonContainer = styled.div`
  @media (max-width: 991px) {
    order: 1;
    margin-top: 20px;
    #BackToDashBoardBtn {
      height: 22px;
    }
  }
`

export const ButtonSet = ({
  onSaveAndNext,
  isLoading,
  isShowPrev = true,
  continueText = "Continue",
  onClickPrevBtn,
  onClickBackToDashboardBtn,
  margin,
  hideBackButton,
  hidePrevButton,
  text = "Back to dashboard",
  disabledNextButton,
  disabledBackButton,
}) => (
  <ButtonSetContainer margin={margin}>
    <BackButtonContainer>
      {!hideBackButton && (
        <Button
          color="secondary"
          variant="text"
          size="small"
          id="BackToDashBoardBtn"
          disabled={isLoading || disabledBackButton}
          startIcon={<HomeIcon />}
          onClick={onClickBackToDashboardBtn}>
          {text}
        </Button>
      )}
    </BackButtonContainer>
    <PrevAndNextContainer isShowPrev={isShowPrev}>
      {!hidePrevButton && (
        <Button
          variant="outlined"
          id="goToPrevPageBtn"
          size="small"
          disabled={isLoading}
          onClick={onClickPrevBtn}
          startIcon={<ArrowBackIosNewIcon />}
          sx={{
            display: isShowPrev ? "inherit" : "none",
            marginRight: "16px",
          }}>
          Prev
        </Button>
      )}
      <Button
        variant="contained"
        color="secondary"
        disabled={isLoading || disabledNextButton}
        id="nextButton"
        onClick={onSaveAndNext}
        size="small">
        <LoadingButton isLoading={isLoading}>{continueText}</LoadingButton>
      </Button>
    </PrevAndNextContainer>
  </ButtonSetContainer>
)
