import React from "react"
import { compose } from "redux"
import _ from "lodash"

import InstructionPage from "./rental-instruction"
import GuidePage from "./rental-guide"
import { isInstruction } from "./constants"
import { withConnect } from "./connect"
/* eslint-disable react/prefer-stateless-function */
export class RentalWrapper extends React.Component {
  render() {
    const { rentalSubmission } = this.props
    return (
      <div>
        {!_.isEmpty(rentalSubmission) && (
          <div>
            {isInstruction(
              _.get(rentalSubmission, "status"),
              _.get(rentalSubmission, "oldVersion"),
            ) ? (
              <InstructionPage />
            ) : (
              <GuidePage />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default compose(withConnect)(RentalWrapper)
