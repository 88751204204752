import React from "react"
import { S24, Hr, Checkbox } from "@rentspree/component-v2"
import styled from "styled-components"
import { formatLocalPhone, generateAddress } from "@rentspree/helper"
import moment from "moment"

import { FormFooter } from "components/buttons/form-footer"
import TextWithLabel from "components/atom/text-with-label"
import SensitiveTextWithLabel from "components/atom/sensitive-text-with-label"
import ReviewInfoComponent from "components/organisms/review-info"
import { trackEvent } from "tracker"
import { LOG_EVENT, TU_PAGE_NAME, APPLICATION } from "tracker/tracker-const"

const InnerReviewWrapper = styled.div`
  margin-top: 27px;
  @media (max-width: 767px) {
    margin-top: 28px;
  }
`

const ReviewComponent = props => {
  const { actions, tuRenter, loading } = props
  const [isSelected, setIsSelected] = React.useState(false)
  const {
    dob,
    homePhoneNumber,
    streetAddressLineOne,
    streetAddressLineTwo,
    city,
    state,
    zip,
  } = tuRenter
  const dateObj = moment(new Date(dob)).utc()
  const formattedDob = dateObj.format("MMM D, YYYY")
  const formattedPhoneNumber = formatLocalPhone(homePhoneNumber)
  const formattedAddress = generateAddress({
    street: streetAddressLineOne,
    unitNumber: streetAddressLineTwo,
    city,
    state,
    zip,
  })

  const contents = [
    [
      {
        render: () => (
          <TextWithLabel
            id="tu-review-first-name"
            label="First Name"
            text={tuRenter.firstName}
          />
        ),
      },
      {
        render: () => (
          <TextWithLabel
            id="tu-review-middle-name"
            label="Middle Name"
            text={tuRenter.middleName || "-"}
          />
        ),
      },
      {
        render: () => (
          <TextWithLabel
            id="tu-review-last-name"
            label="Last Name"
            text={tuRenter.lastName}
          />
        ),
      },
    ],
    [
      {
        render: () => (
          <TextWithLabel
            id="tu-review-dob"
            label="Date of birth"
            text={formattedDob}
          />
        ),
      },
      {
        render: () => (
          <TextWithLabel
            id="tu-review-home-phone"
            label="Home phone no."
            text={formattedPhoneNumber}
          />
        ),
      },
      {
        render: () => (
          <SensitiveTextWithLabel
            id="tu-review-ssn"
            label="SSN/ITIN"
            text={tuRenter.ssn.slice(-4)}
            eventTracker={() => {
              trackEvent({
                ...LOG_EVENT.rental_v3_2.revealSsn,
                customProperties: {
                  pages: TU_PAGE_NAME.TU_REVIEW,
                  application: APPLICATION.TU,
                },
              })
            }}
          />
        ),
      },
    ],
    [
      {
        render: () => (
          <TextWithLabel
            id="tu-review-address"
            label="Address"
            text={formattedAddress}
          />
        ),
      },
    ],
  ]

  return (
    <InnerReviewWrapper className="inner-review-wrapper">
      <div className="personal-info-tu">
        <S24>Personal Info</S24>
        <Hr margin="0 0 20px 0px" />
      </div>
      <ReviewInfoComponent id="personal-review-info" contents={contents} />
      <Hr margin="none" />
      <Checkbox
        id="checkedBox"
        dataTestId="tu-review-confirm-checkbox"
        isSelected={isSelected}
        onClick={() => setIsSelected(!isSelected)}
        label="I confirm the information above is correct."
        margin="0"
        simple
      />
      <FormFooter
        cancelBtn={{
          id: "tuRenterReviewPageCancelBtn",
          onClick: () => {
            actions.unsetIsShowReviewFlag()
            actions.redirect()
          },
          disabled: loading.components,
        }}
        backBtn={{
          id: "tuRenterReviewPageBackBtn",
          onClick: () => {
            actions.unsetIsShowReviewFlag()
            trackEvent({
              ...LOG_EVENT.rental_v3_2.backToEdit,
              customProperties: {
                checkbox: isSelected.toString(),
                application: APPLICATION.TU,
              },
            })
          },
          disabled: loading.components,
        }}
        continueBtn={{
          id: "tuRenterReviewPageContinueBtn",
          text: "Continue",
          disabled: !isSelected || loading.components,
          loading: loading.components,
          loaded: loading.success,
          onClick: () => {
            actions.startNewScreening(tuRenter)
            trackEvent({
              ...LOG_EVENT.rental_v3_2.confirm,
              customProperties: {
                application: APPLICATION.TU,
              },
            })
          },
        }}
        backBtnTitle="Edit"
      />
    </InnerReviewWrapper>
  )
}
export default ReviewComponent
