import { HTML5_FMT } from "moment"

export const TYPES_COMPONENT = {
  TEXT_BOX: "TEXT_BOX",
  CHECK_BOX: "CHECK_BOX",
  INITIAL_SIGNS: "INITIAL_SIGNS",
  SIGNATURE_SIGNS: "SIGNATURE_SIGNS",
  DATE_SIGNS: "DATE_SIGNS",
  ADDRESS: "ADDRESS",
  DATE_BOX: "DATE_BOX",
  AMOUNT_BOX: "AMOUNT_BOX",
  TIME_BOX: "TIME_BOX",
}

export const SIGNABLE_COMPONENT = [
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
]

export const HAS_MOMENT_VALIDATION = [
  TYPES_COMPONENT.DATE_BOX,
  TYPES_COMPONENT.TIME_BOX,
]

export const VALIDATION_FORMAT = {
  DATE_BOX: HTML5_FMT.DATE,
  TIME_BOX: "hh:mm",
}

export const CONFIRM_MODAL_TEXT = {
  TITLE: "Recheck your information",
  INFO: "After you finish signing, you won’t be able to edit the application.",
  SUBMIT: "Finish signing",
  CANCEL: "Recheck",
}

export const HEADER_TITLE = "C.A.R. Application to Rent/Screening Fee"

export const TERMS_AND_CONDITIONS = {
  TERMS: [
    {
      text:
        "I agree to conduct transactions electronically. I certify that I have access to an internet connection and an electronic device with a modern browser, equipped with ability to view PDF documents or other electronic attachments, and that I have access to an email account through which I can view, download and retain these documents as necessary. I consent to the use of electronic signatures in connection with this agreement and any other agreements related to this property and to receive all notices and copies of documents, including those that are legally required to be delivered to me in writing, at the email address I have provided as part of my application or to the landlord or agent for the property in question, which I will keep updated. I also understand that I may withdraw this consent at any time by sending an email to optout@rentspree.com with the subject line “Opt-Out.” Doing so will mean that the landlord or agent will send me paper copies of documents and conduct transactions in writing from thereon with respect to this property. I will otherwise print documents you send me if I want paper copies or contact the landlord or agent to obtain paper copies.",
    },
  ],
  TITLE: "E-Sign and E-Delivery Consent",
}

export const ERROR_MESSAGES_FINISH_SIGNING =
  "Please sign or fill in all required fields before finish signing."
