import { BlueGradientButton } from "@rentspree/component-v2"
import React from "react"
import styled from "styled-components"
import { withResizeDetector } from "react-resize-detector"
import ArrowButton from "../../atom/arrow-button"

export const StyledDiv = styled.div`
  ${({ large }) => `display: ${large ? "block" : "inline-block"}`};
`
export const FixedWidthAbleBlueGradientButton = styled(BlueGradientButton)`
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
`

export const SignFlag = ({ arrowHeadOffset, buttonType, ...rest }) => {
  const divRef = React.useRef(null)
  let button
  switch (buttonType) {
    case "arrow":
      button = (
        <ArrowButton
          bodyLength={`${divRef?.current?.offsetWidth - arrowHeadOffset}px`}
          {...rest}
        />
      )
      break
    default:
      button = <FixedWidthAbleBlueGradientButton {...rest} />
      break
  }
  return (
    <StyledDiv ref={divRef} large={rest.large}>
      {button}
    </StyledDiv>
  )
}

SignFlag.defaultProps = {
  arrowHeadOffset: 10,
  color: "white",
  fontSize: "14px",
  semiBold: true,
  small: true,
  shadow: true,
  width: "83px",
  height: "35px",
  id: "signFlag",
}

const AdaptiveSignFlag = withResizeDetector(SignFlag)

export default AdaptiveSignFlag
