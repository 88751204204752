export const SIGN_MODAL_MODE = {
  NEW: "newSign",
  EDIT: "editSign",
}

export const SIGNATURE_MODAL_CONSENT_TEXT =
  "By selecting Adopt and Sign, I agree that the signature and initials will be the electronic representation of my signature and initials for all purposes when I (or my agent) use them on documents, including legally binding contracts - just the same as a pen-and-paper signature or initial."

export const SIGNATURE_FORM_SUBMIT_TEXT = {
  NEW_MODE: "Adopt",
  EDIT_MODE: "Replace All",
}

export const SIGNATURE_FORM_TITLE_TEXT = {
  NEW_MODE: "Signature",
  EDIT_MODE: "Edit Signature",
}

export const SIGNATURE_TYPE = {
  TYPE: "type",
  DRAW: "draw",
}

export const SIGNATURE_TYPE_LABEL = {
  TYPE: "Type",
  DRAW: "Draw",
}
