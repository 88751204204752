import React, { useState } from "react"
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from "@mui/material"
import Button from "@rentspree/component-2023.components.atoms.button"
import Checkbox from "@rentspree/component-2023.components.atoms.checkbox"
import Dialog from "@rentspree/component-2023.components.atoms.dialog"
import DialogTitle from "@rentspree/component-2023.components.atoms.dialog-title"
import {
  Sheet,
  SheetHeader,
} from "@rentspree/component-2023.components.templates.sheet"
import { TermBox, TermSection, ErrorAlert } from "@rentspree/component-v2"
import { marked } from "marked"
import useMobile from "hooks/use-mobile"

const TermsModalBody = ({
  error,
  selectedCheckBox = false,
  onCheckBoxClick,
  handleClickBtn,
  isLoading = false,
  onClearError,
  terms = [],
  checkboxLabel = "I accept RentSpree's E-Sign and E-Delivery Consent.",
  markdown,
}) => {
  const [showError, setShowError] = useState(false)
  const handleCheckBoxClick = () => {
    setShowError(false)
    if (onCheckBoxClick) {
      onCheckBoxClick()
    }
  }
  const handleAccepted = () => {
    if (selectedCheckBox && handleClickBtn) {
      handleClickBtn()
      return
    }

    if (!selectedCheckBox) {
      setShowError(true)
    }
  }

  return {
    content: (
      <>
        {error && (
          <ErrorAlert
            errorMessage={error.errorMessage}
            secondaryMessage={error.secondaryMessage}
            onClose={onClearError}
            sx={{ margin: "10px 0" }}
          />
        )}
        <TermBox
          padding="15px 20px 0px 20px"
          mPadding="15px 20px 0px 20px"
          height="260"
          hideOverlay>
          {markdown && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: marked(markdown),
              }}
            />
          )}
          {terms && (
            <TermSection
              termsMargin="0px"
              termsSize="14px"
              termsAlign="left"
              terms={terms}
            />
          )}
        </TermBox>
        <FormControlLabel
          label={checkboxLabel}
          style={{ marginTop: "16px" }}
          control={
            <Checkbox
              id="simpleBox"
              data-testid="accept-consent-check-box"
              checked={selectedCheckBox}
              onClick={handleCheckBoxClick}
              color={showError ? "error" : "primary"}
            />
          }
        />
      </>
    ),
    action: (
      <>
        <Button
          variant="contained"
          onClick={handleAccepted}
          color="secondary"
          sx={{ gap: "8px" }}
          disabled={isLoading}>
          {isLoading && <CircularProgress size={16} color="inherit" />}
          Continue
        </Button>
      </>
    ),
  }
}

const MobileTermsModal = ({ children, action, title, isOpen, ...rest }) => (
  <Sheet
    actionSection={
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>{action}</Box>
    }
    open={isOpen}
    hideCloseIcon
    hideActionDivider
    {...rest}>
    <SheetHeader header={title} isCloseButtonHidden />
    <Box sx={{ padding: "20px 20px 0px 20px" }}>{children}</Box>
  </Sheet>
)

const DesktopTermsModal = ({ children, action, title, isOpen, ...rest }) => (
  <Dialog id="consentModal" size="large" open={isOpen} {...rest}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>

    <DialogActions>{action}</DialogActions>
  </Dialog>
)

const TermsModal = ({
  title,
  error,
  selectedCheckBox = false,
  onCheckBoxClick,
  handleClickBtn,
  isLoading = false,
  onClearError,
  terms = [],
  isSuccess,
  checkboxLabel = "I accept RentSpree's E-Sign and E-Delivery Consent.",
  markdown,
  isOpen,
  ...rest
}) => {
  const isMobile = useMobile(1016)

  const { content, action } = TermsModalBody({
    title,
    error,
    selectedCheckBox,
    onCheckBoxClick,
    handleClickBtn,
    isLoading,
    onClearError,
    terms,
    isSuccess,
    checkboxLabel,
    markdown,
  })

  if (isMobile) {
    return (
      <MobileTermsModal action={action} title={title} isOpen={isOpen} {...rest}>
        {content}
      </MobileTermsModal>
    )
  }
  return (
    <DesktopTermsModal action={action} title={title} isOpen={isOpen} {...rest}>
      {content}
    </DesktopTermsModal>
  )
}

export default TermsModal
